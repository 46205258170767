import React from "react";
import Lottie, { Options as LottieOptions } from "react-lottie";

import emptyFaceAnimation from "assets/animations/empty.json";

const EmptyAnimation: React.FC = () => {
  const defaultOptions: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyFaceAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie options={defaultOptions} height={130} width={130} speed={0.6} />
  );
};

export default EmptyAnimation;
