import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: 10,
    },
    center: {
      justifyContent: "center",
      textAlign: "center",
    },
    title: {
      fontSize: "1.5rem",
    },
    cardTitle: {
      fontSize: "1.3rem",
      display: "flex",
      alignItems: "center",
    },
    sadFace: {
      width: "40%",
      maxWidth: 100,
    },
    noGame: {
      width: "20%",
      maxWidth: 60,
    },
    trophy: {
      width: "15%",
      maxWidth: 80,
    },
    hintImage: {
      width: "100%",
    },
    gameIcon: {
      width: 30,
      marginLeft: 10,
    },
    map: {
      width: 25,
    },
  })
);
export default useStyles;
