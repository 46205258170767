import { Routes } from "types";

import {
  Games as GamesIcon,
  Archive,
  PhoneEnabled,
  Lock,
} from "@material-ui/icons";

import {
  Active,
  GamePlay,
  GameInfo,
  Historic,
  ContactUs,
  GameRules,
} from "views/dashboard";

export const adminRoutes: Routes = [
  {
    path: "/your/games",
    name: "Em andamento",
    icon: GamesIcon,
    component: Active,
    layout: "/dashboard",
    isVisible: true,
  },
  {
    path: "/historic",
    name: "Participados",
    icon: Archive,
    component: Historic,
    layout: "/dashboard",
    isVisible: true,
  },
  {
    path: "/game/:qrUuid",
    name: "Jogo",
    icon: GamesIcon,
    component: GamePlay,
    layout: "/dashboard",
    isVisible: false,
  },
  {
    path: "/gameInfo/:gameId",
    name: "Info Jogo",
    icon: GamesIcon,
    component: GameInfo,
    layout: "/dashboard",
    isVisible: false,
  },
  {
    path: "/contact",
    name: "Fale conosco",
    icon: PhoneEnabled,
    component: ContactUs,
    layout: "/dashboard",
    isVisible: true,
  },
  {
    path: "/gameRules/:gameId",
    name: "Regras",
    icon: Lock,
    component: GameRules,
    layout: "/dashboard",
    isVisible: false,
  },
  /*  {
      path: "/list/deliveries",
      name: "Suas entregas",
      //rtlName: "لوحة القيادة",
      icon: "inventory_2",
      component: ListDeliveries,
      layout: "/admin",
    }, */
];
