import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  IconButton,
  Divider,
} from "@material-ui/core";
import Lottie, { Options as LottieOptions } from "react-lottie";
import InputMask from "react-input-mask";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { validateCPF } from "validations-br";
import { DatePicker } from "@material-ui/pickers";

import axios from "config/axios";

import useStyles from "./styles";
import { useHistory, useParams } from "react-router";

import CustomAlert from "components/CustomAlert";
import { Award, GameByQr, RankAward, TopPlayes, User_Award } from "types";

import darkTrophy from "assets/animations/trophy-animation-black.json";
import chaveDourada from "assets/icons/chave_dourada.svg";

import Game from "components/Game";
import CustomLoading from "components/CustomLoading";
import SadFaceAnimation from "components/SadFaceAnimation";
import bau from "assets/icons/bau.png";

interface ExtraInfo {
  cellphone: string;
  doc_name: string;
  cpf: string;
  birthdate: null | Date;
}

yup.addMethod(yup.string, "isCPF", function (errorMessage) {
  return this.test(`test-is-valid`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      validateCPF(value || "") || createError({ path, message: errorMessage })
    );
  });
});

const extraInfoValidate = yup.object({
  doc_name: yup.string().required("Entre com o nome como no documento"),
  cpf: yup.string().required("Entre com o seu cpf").isCPF("CPF inválido"),
  birthdate: yup.date().required("Selecione uma data").nullable(),
  cellphone: yup.string().required("Entre com o seu número celular"),
});

const GamePlay: React.FC = () => {
  const [loading, setLoading] = useState(false),
    [showSadFace, setShowSadFace] = useState(false),
    [disableBtn, setDisableBtn] = useState(false),
    [showWin, setShowWin] =
      useState<{ award: Award; user_award: User_Award }>(),
    [awardInfo, setAwardInfo] = useState<{ awards: Array<Award>; i: number }>(),
    [nickname, setNickname] = useState(""),
    [showNickDialog, setShowNickDialog] = useState(false),
    [showAwardInfo, setShowAwardInfo] = useState(false),
    [rankAwardInfo, setRankAwardInfo] = useState<RankAward>(),
    [showRanking, setShowRanking] = useState<TopPlayes>(),
    [showRankAwardInfo, setShowRankAwardInfo] = useState(false),
    [gameData, setGameData] = useState<GameByQr>();

  const history = useHistory();
  const classes = useStyles();
  const confirm = useConfirm();
  const alertRef = useRef<any>();
  const params = useParams<{ qrUuid?: string }>();

  useEffect(() => {
    if ("geolocation" in navigator) {
      /* geolocation is available */
    } else {
      setShowSadFace(true);
      alertRef?.current.alterAlert(
        "GPS não suportado pelo navegador, por favor troque de navegador",
        "warning"
      );
    }
  }, []);

  const procGameData = async (position?: GeolocationPosition) => {
    axios
      .post(`/QR/gamebyqr`, {
        uuid: params.qrUuid,
        location: [position?.coords?.latitude, position?.coords?.longitude],
      })
      .then(({ data }) => {
        console.log(data);
        setLoading(false);
        setGameData(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setShowSadFace(true);
        switch (error?.response?.data?.status) {
          case 404:
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          case 1:
            alertRef?.current.alterAlert(
              "QR fora do jogo, procure outro QR Code",
              "warning"
            );
            break;
          case 2:
            alertRef?.current.alterAlert("O jogo foi encerrado", "warning");
            break;
          case 3:
            //Processando o horário recebido e mostrando corretamente
            const time = error?.response?.data?.remainingTime;
            let seconds = time / 1000;
            if (seconds > 60) {
              let minutes = seconds / 60,
                pureMin = Math.floor(minutes);
              seconds = Math.round((minutes - pureMin) * 60);
              if (minutes > 60) {
                const floorHour = Math.floor(pureMin / 60);
                pureMin = Math.round((pureMin / 60 - floorHour) * 60);
                return alertRef?.current.alterAlert(
                  `QR já lido, aguarde ${floorHour} horas e ${pureMin} minutos para ler de novo`,
                  "warning"
                );
              }
              return alertRef?.current.alterAlert(
                `QR já lido, aguarde ${pureMin} min e ${seconds} segundos para ler de novo`,
                "warning"
              );
            }
            alertRef?.current.alterAlert(
              `QR já lido, aguarde ${Math.round(
                seconds
              )} segundos para ler de novo`,
              "warning"
            );
            break;
          case 4: {
            console.log(error?.response?.data?.remainingTime);
            const time = error?.response?.data?.remainingTime;
            let seconds = time / 1000;
            if (seconds > 60) {
              const minutes = seconds / 60,
                pureMin = Math.floor(minutes);
              seconds = Math.round((minutes - pureMin) * 60);
              alertRef?.current.alterAlert(
                `Cooldown, aguarde ${pureMin} min e ${seconds} segundos`,
                "warning"
              );
            } else {
              alertRef?.current.alterAlert(
                `Cooldown, aguarde ${Math.round(seconds)} segundos`,
                "warning"
              );
            }
            break;
          }
          case 5:
            alertRef?.current.alterAlert(
              "O jogo não começou ou já terminou",
              "warning"
            );
            break;
          case 6: {
            const data = error?.response?.data;
            alertRef?.current.alterAlert("Jogo encerrado", "warning");
            setShowRanking(data);
            break;
          }
          case 7: {
            alertRef?.current.alterAlert(
              "Fora do horário de funcionamento do local",
              "warning"
            );
            break;
          }
          case 8: {
            alertRef?.current.alterAlert("Muito longe do local!", "warning");
            break;
          }
          default:
            alertRef?.current?.alterAlert(
              "Problema ao pegar dados do jogo",
              "error"
            );
            break;
        }
      });
  };

  const getGameData = useCallback(async () => {
    setLoading((oldState) => !oldState);
    navigator.geolocation.getCurrentPosition(
      function (position) {
        procGameData(position);
      },
      (error) => {
        console.log("Erro ao ler GPS: " + error);
        procGameData();
        /* setShowSadFace(true);
        setLoading(false);
        return alertRef?.current.alterAlert(
          "Permissão de localização não habilitada pelo jogador",
          "warning"
        ); */
      },
      { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
    );

    //eslint-disable-next-line
  }, [params.qrUuid]);

  useEffect(() => {
    getGameData();
  }, [getGameData]);

  const enterGame = async () => {
    setLoading((oldState) => !oldState);
    axios
      .post("/game/enter", {
        qrUuid: params.qrUuid,
        nickname,
      })
      .then(({ data }) => {
        setDisableBtn(true);
        alertRef?.current.alterAlert(
          `Bem vindo ao ${data.gameName}! Você começou com ${data.value} pontos`,
          "success"
        );
        setTimeout(() => {
          history.push("/dashboard/your/games");
        }, 6000);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        setShowSadFace(true);
        switch (error?.response?.data?.status) {
          case 404:
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao pegar dados do jogo",
              "error"
            );
            break;
        }
      });
  };

  const retrievePoints = async (hasWon: number) => {
    setLoading((oldState) => !oldState);
    axios
      .post("/game/retrieve/points", {
        qrUuid: params.qrUuid,
        hasWon,
      })
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setDisableBtn(true);
        if (data.award) {
          alertRef?.current.alterAlert(
            `Parabéns! Você acaba de ganhar um(a) ${data.award.name}`,
            "success"
          );
          setShowWin({ award: data.award, user_award: data.user_award });
        } else {
          alertRef?.current.alterAlert(
            `Pontos capturados! Você recebeu ${data.value} pontos`,
            "success"
          );
          setTimeout(() => {
            history.push("/dashboard/your/games");
          }, 6000);
        }
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 404:
            setShowSadFace(true);
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          default:
            setShowSadFace(true);
            alertRef?.current.alterAlert(
              "Problema ao pegar dados do jogo",
              "error"
            );
            break;
        }
      });
  };

  const registerFailedGame = async () => {
    setLoading((oldState) => !oldState);
    axios
      .post("/game/failed/game", {
        qrUuid: params.qrUuid,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        alertRef?.current.alterAlert(
          "Ahh parece que não foi dessa vez, tente novamente",
          "warning"
        );
        setTimeout(() => {
          history.push("/dashboard/your/games");
        }, 6000);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        setShowSadFace(true);
        switch (error?.response?.data?.status) {
          case 404:
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao atualizar dados",
              "error"
            );
            break;
        }
      });
  };

  const hasWon = (hasWon: number) => {
    console.log("hasdWon", hasWon);
    if (hasWon > 0) {
      retrievePoints(hasWon);
    } else {
      registerFailedGame();
      setDisableBtn(true);
    }
  };

  const registerConfirmed = async () => {
    setLoading((oldState) => !oldState);
    axios
      .put("/game/confirm/award", {
        user_awardId: showWin?.user_award._id,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alertRef?.current.alterAlert(
              "Problema ao atualizar dados",
              "error"
            );
            break;
        }
      });
  };

  const updateProfile = async (values: ExtraInfo) => {
    setLoading(true);
    axios
      .put("/user/update", {
        ...values,
        cellphone: values.cellphone.replace(/[^0-9]/g, ""),
        cpf: values.cpf.replace(/[^0-9]/g, ""),
      })
      .then(() => {
        alertRef?.current.alterAlert("Perfil atualizado!", "success");
        procGameData();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response.data);
        switch (error?.response?.data?.status) {
          case 1:
            alertRef?.current.alterAlert("CPF já em uso", "warning");
            formik.setFieldError("cpf", "Entre com outro CPF");
            break;
          case 2:
            alertRef?.current.alterAlert("Celular já em uso", "warning");
            formik.setFieldError("cellphone", "Entre com outro celular");
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao atualizar dados",
              "error"
            );
            break;
        }
      });
  };

  const formik = useFormik<ExtraInfo>({
    initialValues: {
      doc_name: "",
      cpf: "",
      cellphone: "",
      birthdate: null,
    },
    validationSchema: extraInfoValidate,
    onSubmit: updateProfile,
  });

  const trophyOptions: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: darkTrophy,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (loading) {
    return (
      <div className={classes.center}>
        <CustomAlert ref={alertRef} />
        <CustomLoading />
      </div>
    );
  }

  if (showRanking && showRanking?.game?.topPlayers) {
    return (
      <>
        <CustomAlert ref={alertRef} />
        <Paper className={classes.center} style={{ padding: 20 }}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Typography variant="h5" style={{ marginTop: 10 }}>
                Esta partida foi encerrada!
              </Typography>
              <Typography style={{ marginTop: 10 }}>
                Obrigado a todos por participar
              </Typography>
            </Grid>
            {showRanking.game.endInfo && (
              <Grid item>
                <Typography style={{ fontWeight: "bold" }}>
                  {showRanking.game.endInfo}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              {showRanking?.game?.topPlayers.map((e, i) => (
                <Grid
                  key={i}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Typography variant="body1" align="center">
                      {i + 1}° - {e.nickname ? e.nickname : `Jogador ${i}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" align="center">
                      <b>{e.gameValue} pontos</b>
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {showRanking.rankAwards.length > 0 && (
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item style={{ marginBottom: 10 }}>
                    <Typography variant={"h6"}>Prêmios</Typography>
                  </Grid>
                  {showRanking.rankAwards.map((e) => {
                    return (
                      <Grid item>
                        <Paper className={classes.paper}>
                          <Typography style={{ textAlign: "center" }}>
                            <b>{e.winPosition + 1}º</b> lugar
                          </Typography>
                          <Grid container direction="row">
                            <Grid item>
                              <Typography>{e.name}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                Retirar em: <b>{e?.retrievePlace?.name}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {showRanking.user_game && (
              <Grid item>
                <Typography variant={"h6"}>
                  Suas informações para a premiação
                </Typography>
                <br />
                {showRanking?.user_game?.user.doc_name && (
                  <Typography>
                    Seu nome:<b> {showRanking?.user_game?.user.doc_name}</b>
                  </Typography>
                )}
                {showRanking?.user_game?.user.cpf && (
                  <Typography>
                    Seu CPF:<b> {showRanking?.user_game?.user.cpf}</b>
                  </Typography>
                )}
                <Typography>
                  Seu nickname:<b> {showRanking?.user_game?.nickname}</b>
                </Typography>
                <Typography>
                  Seus pontos:<b> {showRanking?.user_game?.gameValue}</b>
                </Typography>
                <Typography>
                  Seu email:<b> {showRanking?.user_game?.user.email}</b>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </>
    );
  }

  if (showSadFace) {
    return (
      <div>
        <CustomAlert ref={alertRef} />
        <Paper className={classes.center} style={{ padding: 20 }}>
          <SadFaceAnimation />
          <Typography variant="h5" style={{ marginTop: 10 }}>
            Ops!
          </Typography>
          <br />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push("/dashboard/your/games")}
          >
            Voltar
          </Button>
        </Paper>
      </div>
    );
  }

  if (showWin) {
    return (
      <div>
        <CustomAlert ref={alertRef} />
        <Paper className={classes.paper}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h1" className={classes.title}>
                Retire sua recompensa agora!
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <b>{showWin.award?.name}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Local: <b>{showWin?.award?.retrievePlace?.name}</b>
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={showWin?.award?.image}
                alt="Imagem local"
                width="100%"
              />
            </Grid>
            <Grid item className={classes.trophyContainer}>
              <Lottie options={trophyOptions} height={160} width={160} />
            </Grid>
            <Grid item>
              <Typography variant="body1" align="center">
                Para retirar mostre essa tela e diga a senha:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                <b>{showWin?.award?.passphrase}</b>
              </Typography>
            </Grid>
            <Grid item className={classes.center}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  confirm({
                    description:
                      "Você não poderá retornar a essa tela! Somente essa tela garante a sua recompensa.",
                    title: "Voltar?",
                    confirmationText: "Retirei",
                    cancellationText: "Cancelar",
                  })
                    .then(() => {
                      registerConfirmed().then(() => {
                        history.push("/dashboard/your/games");
                      });
                    })
                    .catch(() => {});
                }}
              >
                Retirei a recompensa
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }

  if (gameData?.user.playing && !gameData.user.hasExtraInfo) {
    return (
      <div>
        <CustomAlert ref={alertRef} />
        <Paper className={classes.paper}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5">Finalize o seu cadastro!</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Para que você possa ganhar os prêmios ao final do jogo
                necessitamos de mais alguns dados. Lembrando que somente será
                entregue um prêmio por conta com CPF cadastrado. Será necessária
                a apresentação do documento para retirar o prêmio.
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="doc_name"
                name="doc_name"
                label="Nome como no documento"
                value={formik.values.doc_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.doc_name && Boolean(formik.errors.doc_name)
                }
                helperText={formik.touched.doc_name && formik.errors.doc_name}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
              />
            </Grid>
            <Grid item>
              <InputMask
                mask="999.999.999-99"
                value={formik.values.cpf}
                onChange={formik.handleChange}
                id="cpf"
                name="cpf"
              >
                {(inputProps: any) => (
                  <TextField
                    fullWidth
                    label="CPF"
                    {...inputProps}
                    value={formik.values.cpf}
                    error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                    helperText={formik.touched.cpf && formik.errors.cpf}
                    style={{ marginTop: "5%" }}
                    inputProps={{ style: { padding: 13 } }}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item>
              <InputMask
                mask="(99) 99999-9999"
                value={formik.values.cellphone}
                onChange={formik.handleChange}
                id="cellphone"
                name="cellphone"
              >
                {(inputProps: any) => (
                  <TextField
                    fullWidth
                    label="Celular"
                    {...inputProps}
                    error={
                      formik.touched.cellphone &&
                      Boolean(formik.errors.cellphone)
                    }
                    helperText={
                      formik.touched.cellphone && formik.errors.cellphone
                    }
                    style={{ marginTop: "5%" }}
                    inputProps={{ style: { padding: 13 } }}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item>
              <DatePicker
                value={formik.values.birthdate}
                fullWidth
                error={
                  formik.touched.birthdate && Boolean(formik.errors.birthdate)
                }
                helperText={formik.touched.birthdate && formik.errors.birthdate}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
                format="dd/MM/yyyy"
                maxDate={moment().add(-18, "years")}
                label="Data de nascimento"
                onChange={(e) => {
                  formik.setFieldValue("birthdate", e);
                }}
              />
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  formik.handleSubmit();
                }}
                color="primary"
                variant="contained"
              >
                Atualizar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }

  if (gameData?.user.playing) {
    return (
      <div>
        <CustomAlert ref={alertRef} />
        <Paper className={classes.paper}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item>
              <Typography variant="subtitle2">Jogo</Typography>
              <Typography variant="h1" className={classes.title}>
                {gameData?.game.name}{" "}
                {gameData.game.icon && (
                  <img
                    src={gameData.game.icon}
                    className={classes.gameIcon}
                    alt="Ícone do jogo"
                  />
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="center">
                Já jogando 🎮️
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="center">
                Seus pontos: <b>{gameData?.user.gameValue || 0}</b>
              </Typography>
            </Grid>
            {gameData.award && (
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      variant="determinate"
                      value={
                        (gameData?.user?.gameValue /
                          gameData?.award?.winValue) *
                        100
                      }
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">
                      {gameData.award.winValue}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}
            {!disableBtn && (
              <Grid item>
                <Game
                  hasWon={hasWon}
                  miniGames={gameData?.game?.miniGames}
                  alertRef={alertRef}
                />
              </Grid>
            )}
            {/* <Grid item className={classes.center}>
              <Button
                variant="contained"
                color="secondary"
                onClick={retrievePoints}
                disabled={disableBtn}
              >
                Recolher pontos
              </Button>
            </Grid> */}
          </Grid>
        </Paper>
      </div>
    );
  }

  return (
    <div>
      <CustomAlert ref={alertRef} />
      <Dialog
        onClose={() => setShowAwardInfo(false)}
        aria-labelledby="simple-dialog-title"
        open={showAwardInfo}
        fullScreen
      >
        <DialogTitle id="simple-dialog-title">
          Recompensa {awardInfo ? awardInfo?.i + 1 : 1}
        </DialogTitle>
        <DialogContent>
          {awardInfo && awardInfo?.awards?.length > 1 && (
            <>
              <Typography align="center">
                Você poderá ganhar um dos {awardInfo?.awards?.length}{" "}
                recompensas abaixo
              </Typography>
              <br />
            </>
          )}
          {awardInfo?.awards?.map((e, i) => (
            <>
              <div key={i}>
                <Typography
                  variant="body1"
                  style={{ fontSize: "1.1rem", textAlign: "center" }}
                >
                  Recompensa: <b>{e?.name}</b>
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontSize: "1.1rem", textAlign: "center" }}
                >
                  Local de retirada: <b>{e?.retrievePlace?.name}</b>
                </Typography>
                {e?.image && (
                  <img
                    src={e?.image}
                    alt="Imagem local"
                    className={classes.retrievePlaceImage}
                  />
                )}
              </div>
              {i !== awardInfo.awards.length - 1 && (
                <Divider className={classes.divider} />
              )}
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAwardInfo(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setShowRankAwardInfo(false)}
        aria-labelledby="simple-dialog-title"
        open={showRankAwardInfo}
        fullScreen
      >
        <DialogTitle id="simple-dialog-title">
          Prêmio de {rankAwardInfo ? rankAwardInfo.winPosition + 1 : 1}° lugar
        </DialogTitle>
        <DialogContent>
          <div>
            <Typography
              variant="body1"
              style={{ fontSize: "1.1rem", textAlign: "center" }}
            >
              Prêmio: <b>{rankAwardInfo?.name}</b>
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: "1.1rem", textAlign: "center" }}
            >
              Local de retirada: <b>{rankAwardInfo?.retrievePlace?.name}</b>
            </Typography>
            {rankAwardInfo?.image && (
              <img
                src={rankAwardInfo?.image}
                alt="Imagem local"
                className={classes.retrievePlaceImage}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRankAwardInfo(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showNickDialog}
        onClose={() => setShowNickDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nome de guerra</DialogTitle>
        <DialogContent>
          <DialogContentText>Escolha o seu nome de jogador</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="nickname"
            label="Nickname"
            type="text"
            fullWidth
            onChange={(e) => setNickname(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNickDialog(false)} color="primary">
            Fechar
          </Button>
          <Button
            onClick={() => {
              setShowNickDialog(false);
              enterGame();
            }}
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item>
          <Paper className={classes.paper}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item>
                <Typography variant="subtitle2">Jogo</Typography>
                <Typography variant="h1" className={classes.title}>
                  {gameData?.game.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Como funciona: <b>{gameData?.game.info}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Local do jogo: <b>{gameData?.game.place.name}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Jogadores participando:{" "}
                  <b>{gameData?.game.countParticipants || 0}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Início da partida:{" "}
                  <b>
                    {moment(gameData?.game?.start).format("DD/MM - HH:mm") || 0}
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Término da partida:{" "}
                  <b>
                    {moment(gameData?.game?.end).format("DD/MM - HH:mm") || 0}
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Funcionamento:{" "}
                  <b>{`${gameData?.game?.place?.workingHours?.start}h até ${gameData?.game?.place?.workingHours?.end}h`}</b>
                </Typography>
              </Grid>
              {gameData?.game && gameData?.game?.rules?.length > 0 && (
                <Grid item>
                  <Typography variant="body1">Regras da partida</Typography>
                </Grid>
              )}
              {gameData?.game.rules.map((e, i) => {
                return (
                  <Grid item key={i}>
                    <Typography style={{ marginLeft: 20 }} variant="body1">
                      {i + 1}º - <b>{e}</b>
                    </Typography>
                  </Grid>
                );
              })}

              <Grid item className={classes.center}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowNickDialog(true)}
                  disabled={disableBtn}
                >
                  Participar do jogo
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {gameData?.rankAwards && gameData?.rankAwards.length > 0 && (
          <Grid item>
            <Paper className={classes.paper}>
              <Typography
                className={classes.cardTitle}
                style={{ marginBottom: 10 }}
              >
                Prêmios
              </Typography>
              {gameData?.rankAwards.map((e, i) => (
                <Grid
                  key={i}
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="body1" align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setRankAwardInfo(e);
                          setShowRankAwardInfo(true);
                        }}
                        endIcon={
                          <img
                            src={bau}
                            alt="Bau"
                            style={{
                              width: "1.5rem",
                              left: 0,
                            }}
                          />
                        }
                      >
                        Prêmio {e.winPosition + 1}° lugar
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Paper>
          </Grid>
        )}
        <Grid item>
          <Paper className={classes.paper}>
            <Typography className={classes.cardTitle}>Recompensas</Typography>
            {gameData?.awards.map((e, i) => (
              <Grid
                key={i}
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="body1" align="center">
                    Recompensa {i + 1}
                    <IconButton
                      onClick={() => {
                        setAwardInfo({ awards: e.data, i });
                        setShowAwardInfo(true);
                      }}
                    >
                      <img
                        className={classes.key}
                        src={chaveDourada}
                        alt="Floresta Rosa"
                        style={{
                          width: "1.2rem",
                          left: 0,
                        }}
                      />
                    </IconButton>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>{e._id} pontos</b>
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default GamePlay;
