import React from "react";
import Lottie, { Options as LottieOptions } from "react-lottie";

import sadFaceAnimation from "assets/animations/sad-face.json";

const SadFaceAnimation: React.FC = () => {
  const defaultOptions: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: sadFaceAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie options={defaultOptions} height={120} width={120} speed={0.7} />
  );
};

export default SadFaceAnimation;
