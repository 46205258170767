import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

import useStyle from "./styles";

const ContactUs: React.FC = () => {
  const classes = useStyle();

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">Informações de contato</Typography>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography variant="body2">Para dúvidas e feedbacks</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>
              Telefone: <a href="tel:+5541988485382">(41) 98848-5382</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              Email:{" "}
              <a href="mailto:service@microsistemas.com.br">
                service@microsistemas.com.br
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ContactUs;
