import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
  Box,
  LinearProgress,
} from "@material-ui/core";

import useStyles from "./styles";
import axios from "config/axios";
import { Hint, UserGames } from "types";

import CustomAlert from "components/CustomAlert";

import { useHistory } from "react-router";

import trophyIcon from "assets/img/trophy.svg";
import mapa from "assets/icons/mapa.svg";

import CustomLoading from "components/CustomLoading";
import EmptyAnimation from "components/EmptyAnimation";
import SadFaceAnimation from "components/SadFaceAnimation";

const Games: React.FC = () => {
  const [games, setGames] = useState<UserGames>([]),
    [loading, setLoading] = useState(false),
    [hint, setHint] = useState<null | Hint>(),
    [showSadFace, setShowSadFace] = useState(false);

  const classes = useStyles();
  const alertRef = useRef<any>();
  const history = useHistory();

  const getGames = async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/user/active/games")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setGames(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        setShowSadFace(true);
        switch (error?.response?.data?.status) {
          case 404:
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          case 1:
            alertRef?.current.alterAlert(
              "QR fora do jogo, procure outro QR Code",
              "warning"
            );
            break;
          case 2:
            alertRef?.current.alterAlert(
              "Você já leu este QR Code, tente ler outro",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao pegar dados do jogo",
              "error"
            );
            break;
        }
      });
  };

  const showHint = async (gameId: string) => {
    setLoading((oldState) => !oldState);
    axios
      .get(`/game/hint/${gameId}`)
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setHint(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        setShowSadFace(true);
        switch (error?.response?.data?.status) {
          case 404:
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          case 1:
            alertRef?.current.alterAlert(
              "Esse jogo não possui dicas!",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao pegar dados do jogo",
              "error"
            );
            break;
        }
      });
  };

  useEffect(() => {
    getGames();
  }, []);

  if (loading) {
    return (
      <div className={classes.center}>
        <CustomAlert ref={alertRef} />
        <CustomLoading />
      </div>
    );
  }

  if (showSadFace) {
    return (
      <div>
        <CustomAlert ref={alertRef} />
        <Paper className={classes.center} style={{ padding: 20 }}>
          <SadFaceAnimation />
          <Typography variant="h5" style={{ marginTop: 10 }}>
            Ops!
          </Typography>
        </Paper>
      </div>
    );
  }

  return (
    <div>
      <CustomAlert ref={alertRef} />
      <Typography variant="h1" className={classes.title}>
        Jogos em andamento
      </Typography>
      <br />
      <Dialog
        onClose={() => setHint(null)}
        aria-labelledby="simple-dialog-title"
        open={hint ? true : false}
      >
        <DialogTitle id="simple-dialog-title">Dica! 💡</DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ fontSize: "1.3rem" }}>
            {hint?.hint}
          </Typography>
          {hint?.image && (
            <img
              src={hint?.image}
              alt="Imagem local"
              className={classes.hintImage}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHint(null)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="stretch"
        spacing={2}
      >
        {games?.map((e, i) => (
          <Grid item key={i}>
            <Paper className={classes.paper}>
              <Typography variant="h1" className={classes.cardTitle}>
                {e?.user_game.game.name}
                {e.user_game.game.icon && (
                  <img
                    src={e.user_game.game.icon}
                    className={classes.gameIcon}
                    alt="Ícone do jogo"
                  />
                )}
              </Typography>
              <br />
              <Typography variant="body1" style={{ marginBottom: 5 }}>
                Local do jogo: <b>{e?.user_game.game.place.name}</b>
              </Typography>
              <Typography variant="body1">
                Nickname: <b>{e?.user_game.nickname}</b>
              </Typography>
              <Typography variant="body1">
                Pontos: <b>{e?.user_game.gameValue}</b>
              </Typography>
              {e.nextAward?.winValue ? (
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      variant="determinate"
                      color="secondary"
                      value={
                        (e.user_game?.gameValue / e.nextAward?.winValue) * 100
                      }
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">
                      {e.nextAward?.winValue}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <div style={{ marginBottom: 10, textAlign: "center" }}>
                  <img
                    src={trophyIcon}
                    className={classes.trophy}
                    alt="Troféu"
                  />
                  <Typography variant="body1" align="center">
                    Conquiste o 1° lugar geral, ao término do jogo
                  </Typography>
                </div>
              )}

              <div style={{ textAlign: "right" }}>
                <Tooltip title="Pegar uma dica!" aria-label="Dica">
                  <Button
                    style={{ marginRight: 10 }}
                    onClick={() => showHint(e.user_game?.game?._id)}
                    variant="contained"
                  >
                    <img src={mapa} alt="Ícone dicas" className={classes.map} />
                  </Button>
                </Tooltip>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    history.push(`/dashboard/gameInfo/${e.user_game.game._id}`);
                  }}
                >
                  Info
                </Button>
              </div>
            </Paper>
          </Grid>
        ))}
        {games?.length === 0 && (
          <Grid item>
            <Paper className={classes.paper}>
              <Typography
                variant="body1"
                align="center"
                style={{ fontSize: "1.1rem" }}
              >
                Nenhum jogo em andamento
              </Typography>
              <div className={classes.center} style={{ marginTop: 10 }}>
                <EmptyAnimation />
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Games;
