import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: 20,
    },
    title: {
      fontSize: "1.5rem",
    },
    center: {
      justifyContent: "center",
      textAlign: "center",
      display: "flex",
    },
    gameIcon: {
      width: 30,
      marginLeft: 10,
    },
  })
);
export default useStyles;
