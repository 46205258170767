import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    background: {
      position: "fixed",
      minHeight: "100%",
      minWidth: 1024,
      weight: "100%",
      height: "auto",
      top: 0,
      right: 0,
      zIndex: -1,
    },
    leftSide: {
      maxWidth: 400,
      height: "100vh",
      order: 2,
      borderRadius: 0,
    },
    cardContent: {},
    title: {
      textAlign: "center",
      fontFamily: "Nunito Sans",
    },
    inputs: {},
    btnLogin: {},
    logo: {
      position: "absolute",
      width: "20vw",
      top: "30%",
      bottom: "20%",
    },
    logoMobile: {
      width: "50vw",
      maxWidth: 200,
    },
    logoMobileDiv: {
      textAlign: "center",
      marginTop: 20,
      marginBottom: 30,
    },
  })
);
export default useStyles;
