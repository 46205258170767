import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import "./assets/css/main.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ThemeProvider } from "@material-ui/core";
import { ConfirmProvider } from "material-ui-confirm";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import prBrLocale from "date-fns/locale/pt-BR";

//import DateFnsUtils from "@date-io/date-fns";
//import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import mainTheme from "./theme";

import DashboardLayout from "layouts/Dashboard";

import RegisterScreen from "./views/auth/Register";
import LoginScreen from "./views/auth/Login";
import ForgotPassword from "./views/auth/ForgotPassword";

import { getUserToken } from "functions/userToken";

const hist = createBrowserHistory();

interface IProps {
  [key: string]: any;
}

function PrivateRoute({ children, ...rest }: IProps) {
  return (
    <Route
      {...rest}
      render={() => (getUserToken() ? children : <Redirect to="/login" />)}
    />
  );
}

function PrivateQRRoute({ ...rest }: IProps) {
  const qrUuid = rest.computedMatch.params.qrUuid;
  return (
    <Route
      {...rest}
      render={() =>
        getUserToken() ? (
          <Redirect to={`/dashboard/game/${qrUuid}`} />
        ) : (
          <Redirect to={`/login/${qrUuid}`} />
        )
      }
    />
  );
}

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={prBrLocale}>
    <ThemeProvider theme={mainTheme}>
      <ConfirmProvider>
        <Router history={hist}>
          <Switch>
            <PrivateRoute path="/dashboard/*">
              <Route path="/dashboard/*" component={DashboardLayout} />
            </PrivateRoute>
            <PrivateQRRoute path="/qr/:qrUuid" />
            <Route path="/register/:qrUuid" component={RegisterScreen} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/forgot/password" component={ForgotPassword} />
            <Route path="/login/:qrUuid" component={LoginScreen} />
            <Route path="/login" component={LoginScreen} />
            {getUserToken() && <Redirect to="/dashboard/your/games" />}
            <Redirect exact from="/" to="/login" />
          </Switch>
        </Router>
      </ConfirmProvider>
    </ThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an a@types/historynalytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
