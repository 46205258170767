import React, { useEffect, useState, useRef } from "react";
import { Paper, Typography, Grid, Button } from "@material-ui/core";

import useStyles from "./styles";
import axios from "config/axios";
import { UserOldGames } from "types";

import CustomAlert from "components/CustomAlert";

import { useHistory } from "react-router";
import CustomLoading from "components/CustomLoading";
import EmptyAnimation from "components/EmptyAnimation";
import SadFaceAnimation from "components/SadFaceAnimation";

const Historic: React.FC = () => {
  const [games, setGames] = useState<UserOldGames>([]),
    [loading, setLoading] = useState(false),
    [showSadFace, setShowSadFace] = useState(false);

  const classes = useStyles();
  const alertRef = useRef<any>();
  const history = useHistory();

  const getOldGames = async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/user/old/games")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setGames(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        setShowSadFace(true);
        switch (error?.response?.data?.status) {
          case 404:
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao pegar dados do jogo",
              "error"
            );
            break;
        }
      });
  };

  useEffect(() => {
    getOldGames();
  }, []);

  if (loading) {
    return (
      <div className={classes.center}>
        <CustomLoading />
      </div>
    );
  }

  if (showSadFace) {
    return (
      <div>
        <CustomAlert ref={alertRef} />
        <Paper className={classes.center} style={{ padding: 20 }}>
          <SadFaceAnimation />
          <Typography variant="h5" style={{ marginTop: 10 }}>
            Ops!
          </Typography>
        </Paper>
      </div>
    );
  }

  return (
    <div>
      <CustomAlert ref={alertRef} />
      <Typography variant="h1" className={classes.title}>
        Jogos participados
      </Typography>
      <br />
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="stretch"
        spacing={2}
      >
        {games?.map((e, i) => (
          <Grid item key={i}>
            <Paper className={classes.paper}>
              <Typography variant="h1" className={classes.cardTitle}>
                {e?.game.name}
              </Typography>
              <br />
              <Typography variant="body1">
                Local do jogo: <b>{e?.game.place.name}</b>
              </Typography>
              <Typography variant="body1">
                Seus pontos: <b>{e?.gameValue}</b>
              </Typography>
              <div style={{ textAlign: "right" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    history.push(`/dashboard/gameInfo/${e.game._id}`);
                  }}
                >
                  Info
                </Button>
              </div>
            </Paper>
          </Grid>
        ))}
        {games?.length === 0 && (
          <Grid item>
            <Paper className={classes.paper}>
              <Typography
                variant="body1"
                align="center"
                style={{ fontSize: "1.1rem" }}
              >
                Nenhum jogo participado
              </Typography>
              <div className={classes.center} style={{ marginTop: 10 }}>
                <EmptyAnimation />
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Historic;
