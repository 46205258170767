import React, { useEffect, useState, useRef } from "react";
import Lottie, { Options as LottieOptions } from "react-lottie";

import { GameProps } from "types";

import consoleAnimation from "assets/animations/console-loading.json";

//import { PickNumbers, FastClick, Trivia } from "components/Games";
import { getRandomArbitrary } from "functions/getRandomNumber";

import useStyle from "./styles";
import { useParams } from "react-router";

const Game: React.FC<GameProps> = (props) => {
  const [game, setGame] = useState<number>(),
    [loading, setLoading] = useState(false);

  const iframe = useRef<any>();

  const classes = useStyle();

  const params = useParams<{ qrUuid: string }>();

  useEffect(() => {
    window.addEventListener("message", (event) => {
      console.log(event?.origin);
      if (
        event.origin === "http://localhost:3001" ||
        event.origin === "https://iframe.picbit.com.br"
      ) {
        if (typeof event?.data?.hasWon === "number") {
          return props.hasWon(event.data.hasWon);
        }
      }
    });
  }, [props]);

  const randomGame = () => {
    const gameStorage = window.localStorage.getItem("randomGame");
    if (gameStorage) {
      return setGame(parseInt(gameStorage));
    }
    //Colocando loading proprosital para aumentar a tensão
    setLoading(true);
    setTimeout(() => {
      const random = getRandomArbitrary(1, 5);
      setGame(random);
      window.localStorage.setItem("randomGame", random.toString());
    }, getRandomArbitrary(1000, 3000));
  };

  const renderGame = () => {
    const { miniGames } = props;

    if (miniGames.length === 0) {
      props.hasWon(1);
      return <></>;
    }

    const random = getRandomArbitrary(0, miniGames.length - 1);

    const src =
      process.env.NODE_ENV === "development"
        ? `http://localhost:3001/mmcr_local_trivia?qrUuid=${params.qrUuid}&host=${window.location.origin}`
        : `${miniGames[random]}?qrUuid=${params.qrUuid}&host=${window.location.origin}`;

    return (
      <iframe
        src={src}
        width={"99%"}
        height={750}
        title="Game"
        ref={iframe}
        className={classes.iframe}
      />
    );

    /* if (!game) {
      return <></>;
    }
    switch (game) {
      case 1:
        return <FastClick {...props} />;
      case 2:
        return <PickNumbers {...props} />;
      case 3:
        return (
          <Trivia
            {...props}
            loading={loading}
            setLoading={setLoading}
            setGame={setGame}
          />
        );
      default:
        return (
          <Trivia
            {...props}
            loading={loading}
            setLoading={setLoading}
            setGame={setGame}
          />
        );
    } */
  };

  useEffect(() => {
    if (game) {
      setLoading(false);
    }
  }, [game]);

  useEffect(() => {
    randomGame();
  }, []);

  const defaultOptions: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: consoleAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (loading) {
    return (
      <div>
        <Lottie options={defaultOptions} height={200} width={150} />
      </div>
    );
  }

  return <div>{renderGame()}</div>;
};

export default Game;
