import React, { useState, useRef, useEffect } from "react";

import {
  TextField,
  Card,
  CardContent,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  FormHelperText,
  useMediaQuery,
} from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import useMobile from "use-mobile-detect-hook";

import useStyles from "./styles";
import axios from "config/axios";

import { useHistory, useParams } from "react-router-dom";

import CustomAlert from "components/CustomAlert";

import firebase from "config/firebase";

import background from "assets/img/blob_back.svg";
import logo from "assets/img/picbit_logo.svg";
import logo_branco from "assets/img/picbit_logo_branco.svg";

const RegisterSchema = yup.object().shape({
  name: yup.string().min(2, "Muito curto").required("O nome é obrigatório"),
  email: yup.string().email("Email inválido").required("O email é obrigatório"),
  confEmail: yup
    .string()
    .required("Confirme o seu email")
    .oneOf([yup.ref("email"), null], "Email devem ser iguais"),
  password: yup.string().required("A senha é obrigatória"),
  checkbox: yup.boolean().isTrue("Para continuar aceite os termos de uso"),
});

function Login() {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const mobile = useMobile();

  const params = useParams<{ qrUuid?: string }>();

  const matches = useMediaQuery("(max-width:1280px)");

  const alertRef = useRef<any>();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      confEmail: "",
      password: "",
      checkbox: false,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      register(values);
    },
  });

  useEffect(() => {
    if (params?.qrUuid) {
      alertRef?.current.alterAlert("Agora só basta criar sua conta!", "info");
    }
  }, [params]);

  const register = async (values: any) => {
    setLoading(true);
    firebase
      .emailRegister(values.email, values.password)
      .then(async (user) => {
        axios
          .post("/auth/register", {
            name: values.name,
            firebaseToken: await user.user?.getIdToken(true),
          })
          .then(() => {
            firebase
              .sendVerifyEmail()
              .then(async () => {
                setLoading(false);
                alertRef?.current.alterAlert(
                  "Conta criada! Faça login",
                  "success"
                );
                setTimeout(() => {
                  history.goBack();
                }, 7000);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            window.scrollTo(0, 0);
            setLoading(false);
            console.log(error);
            alertRef?.current.alterAlert(
              "Problema ao registrar, tente novamente",
              "error"
            );
          });
      })
      .catch((error) => {
        setLoading(false);
        window.scrollTo(0, 0);
        let { code } = error;
        switch (code) {
          case "auth/weak-password":
            alertRef?.current.alterAlert("Senha muito fraca", "warning");
            break;
          case "auth/email-already-in-use":
            alertRef?.current.alterAlert("Email já em uso", "warning");
            break;
          case "auth/invalid-email":
            alertRef?.current.alterAlert("Email inválido", "warning");
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao cadastrar, tente novamente",
              "error"
            );
            break;
        }
      });
  };

  return (
    <div>
      {loading && <LinearProgress />}
      <CustomAlert ref={alertRef} />
      <img
        className={classes.background}
        src={background}
        alt="Imagem de fundo"
      />
      <div style={{ flex: 1, textAlign: "center" }}>
        {!mobile.isMobile() && (
          <img
            className={classes.logo}
            src={logo_branco}
            alt="Logo patota games"
            style={matches ? { marginLeft: "4vw" } : undefined}
          />
        )}
      </div>
      <div>
        <Card className={classes.leftSide} style={{ borderRadius: 0 }}>
          <CardContent className={classes.cardContent}>
            {mobile.isMobile() && (
              <div className={classes.logoMobileDiv}>
                <img
                  className={classes.logoMobile}
                  src={logo}
                  alt="Logo patota games"
                />
              </div>
            )}
            <Typography variant="h4" className={classes.title}>
              Olá
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                placeholder="Nome"
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{ style: { padding: 13 } }}
              />
              <TextField
                fullWidth
                placeholder="Email"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
              />
              <TextField
                fullWidth
                placeholder="Confirme o email"
                type="email"
                name="confEmail"
                value={formik.values.confEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.confEmail && Boolean(formik.errors.confEmail)
                }
                helperText={formik.touched.confEmail && formik.errors.confEmail}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
              />
              <TextField
                fullWidth
                placeholder="Senha"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
              />
              <FormControlLabel
                style={{ marginTop: "5%" }}
                control={
                  <Checkbox
                    checked={formik.values.checkbox}
                    onChange={formik.handleChange}
                    name="checkbox"
                    color="primary"
                    inputProps={{ "aria-label": "Termos de uso" }}
                  />
                }
                label={
                  <Typography>
                    Li e aceito os{" "}
                    <a
                      href={`${window.location.origin}/termos_de_uso.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      termos de uso
                    </a>
                  </Typography>
                }
              />
              <FormHelperText
                error={
                  formik.touched.checkbox && Boolean(formik.errors.checkbox)
                }
              >
                {formik.touched.checkbox && formik.errors.checkbox}
              </FormHelperText>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                disabled={loading}
                type="submit"
                style={{ marginTop: 15 }}
              >
                Criar
              </Button>
              <div style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push("/login");
                  }}
                  style={{ marginTop: 15 }}
                >
                  Já tem conta?
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Login;
