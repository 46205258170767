import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Divider,
} from "@material-ui/core";

import useStyles from "./styles";
import axios from "config/axios";
import { GameInfo, Award, RankAward } from "types";

import CustomAlert from "components/CustomAlert";

import { useHistory, useParams } from "react-router";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowLeft,
} from "@material-ui/icons";
import SadFaceAnimation from "components/SadFaceAnimation";
import CustomLoading from "components/CustomLoading";

import chaveDourada from "assets/icons/chave_dourada.svg";
import primeiro_lugar from "assets/icons/primeiro_lugar.png";
import segundo_lugar from "assets/icons/segundo_lugar.png";
import terceiro_lugar from "assets/icons/terceiro_lugar.png";
import bau from "assets/icons/bau.png";

const GamesInfo: React.FC = () => {
  const [info, setInfo] = useState<GameInfo>(),
    [loading, setLoading] = useState(false),
    [showRanking, setShowRanking] = useState(false),
    [awardInfo, setAwardInfo] = useState<{ awards: Array<Award>; i: number }>(),
    [rankAwardInfo, setRankAwardInfo] = useState<RankAward>(),
    [showAwardInfo, setShowAwardInfo] = useState(false),
    [showRankAwardInfo, setShowRankAwardInfo] = useState(false),
    [showSadFace, setShowSadFace] = useState(false);

  const classes = useStyles();
  const alertRef = useRef<any>();
  const history = useHistory();
  const params = useParams<{ gameId?: string }>();

  const getGames = useCallback(async () => {
    setLoading((oldState) => !oldState);
    axios
      .get(`/game/info/${params.gameId}`)
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setInfo(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        setShowSadFace(true);
        switch (error.response.data.status) {
          case 404:
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          case 1:
            alertRef?.current.alterAlert(
              "QR fora do jogo, procure outro QR Code",
              "warning"
            );
            break;
          case 2:
            alertRef?.current.alterAlert(
              "Você já leu este QR Code, tente ler outro",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao pegar dados do jogo",
              "error"
            );
            break;
        }
      });
  }, [params.gameId]);

  useEffect(() => {
    getGames();
  }, [getGames]);

  const switchPlace = (index: number) => {
    switch (index) {
      case 0:
        return primeiro_lugar;
      case 1:
        return segundo_lugar;
      case 2:
        return terceiro_lugar;
    }
  };

  if (loading) {
    return (
      <div className={classes.center}>
        <CustomAlert ref={alertRef} />
        <CustomLoading />
      </div>
    );
  }

  if (showSadFace) {
    return (
      <div>
        <CustomAlert ref={alertRef} />
        <Paper className={classes.center} style={{ padding: 20 }}>
          <SadFaceAnimation />
          <Typography variant="h5" style={{ marginTop: 10 }}>
            Ops!
          </Typography>
        </Paper>
      </div>
    );
  }

  return (
    <div>
      <CustomAlert ref={alertRef} />
      <Dialog
        onClose={() => setShowAwardInfo(false)}
        aria-labelledby="simple-dialog-title"
        open={showAwardInfo}
        fullScreen
      >
        <DialogTitle id="simple-dialog-title">
          Recompensa {awardInfo ? awardInfo?.i + 1 : 1}
        </DialogTitle>
        <DialogContent>
          {awardInfo && awardInfo?.awards?.length > 1 && (
            <>
              <Typography align="center">
                Você poderá ganhar uma das {awardInfo?.awards?.length}{" "}
                recompensas abaixo
              </Typography>
              <br />
            </>
          )}

          {awardInfo?.awards?.map((e, i) => (
            <div key={i}>
              <div key={i}>
                <Typography
                  variant="body1"
                  style={{ fontSize: "1.1rem", textAlign: "center" }}
                >
                  Recompensa: <b>{e?.name}</b>
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontSize: "1.1rem", textAlign: "center" }}
                >
                  Local de retirada: <b>{e?.retrievePlace?.name}</b>
                </Typography>
                {e?.image && (
                  <img
                    src={e?.image}
                    alt="Imagem local"
                    className={classes.retrievePlaceImage}
                  />
                )}
              </div>
              {i !== awardInfo.awards.length - 1 && (
                <Divider className={classes.divider} />
              )}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAwardInfo(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setShowRankAwardInfo(false)}
        aria-labelledby="simple-dialog-title"
        open={showRankAwardInfo}
        fullScreen
      >
        <DialogTitle id="simple-dialog-title">
          Prêmio de {rankAwardInfo ? rankAwardInfo.winPosition + 1 : 1}° lugar
        </DialogTitle>
        <DialogContent>
          <div>
            <Typography
              variant="body1"
              style={{ fontSize: "1.1rem", textAlign: "center" }}
            >
              Prêmio: <b>{rankAwardInfo?.name}</b>
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: "1.1rem", textAlign: "center" }}
            >
              Local de retirada: <b>{rankAwardInfo?.retrievePlace?.name}</b>
            </Typography>
            {rankAwardInfo?.image && (
              <img
                src={rankAwardInfo?.image}
                alt="Imagem local"
                className={classes.retrievePlaceImage}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRankAwardInfo(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h1" className={classes.title}>
        <IconButton
          size={"small"}
          style={{ marginRight: 10 }}
          onClick={() => {
            history.goBack();
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        {info?.game.name}
        {info?.game.icon && (
          <img
            src={info?.game.icon}
            className={classes.gameIcon}
            alt="Ícone do jogo"
          />
        )}
      </Typography>
      <br />
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item>
          <Paper className={classes.paper}>
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item>
                <Typography variant="body1">
                  Objetivo: <b>{info?.game.info}</b>
                </Typography>
              </Grid>
              <Grid item>
                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      history.push(`/dashboard/gameRules/${params.gameId}`)
                    }
                  >
                    Regras
                  </Button>
                </div>
              </Grid>
              {info?.game.obs && (
                <Grid item>
                  <Typography variant="body2" align="center">
                    Observações: {info?.game.obs}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Typography
              className={classes.cardTitle}
              style={{ marginBottom: 10 }}
            >
              Prêmios
            </Typography>
            {info?.rankAwards.map((e, i) => (
              <Grid
                key={i}
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="body1" align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setRankAwardInfo(e);
                        setShowRankAwardInfo(true);
                      }}
                      endIcon={
                        <img
                          src={bau}
                          alt="Bau"
                          style={{
                            width: "1.5rem",
                            left: 0,
                          }}
                        />
                      }
                    >
                      Prêmio {e.winPosition + 1}° lugar
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Typography className={classes.cardTitle}>Recompensas</Typography>
            {info?.awards.map((e, i) => (
              <Grid
                key={i}
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="body1" align="center">
                    Recompensa {i + 1}
                    <IconButton
                      onClick={() => {
                        setAwardInfo({ awards: e.data, i });
                        setShowAwardInfo(true);
                      }}
                    >
                      <img
                        className={classes.key}
                        src={chaveDourada}
                        alt="Chave"
                        style={{
                          width: "1.2rem",
                          left: 0,
                        }}
                      />
                    </IconButton>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>{e._id} pontos</b>
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <Paper className={classes.paper}>
            <Typography className={classes.cardTitle}>Ranking atual</Typography>
            {info?.game.topPlayers.slice(0, 3).map((e, i) => (
              <Grid
                key={i}
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
                style={{ marginTop: 25, marginBottom: 25 }}
              >
                <Grid item xs={6} className={classes.center}>
                  <img
                    src={switchPlace(i)}
                    alt="Ícone troféus"
                    className={classes.trofeus}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    className={classes.placesText}
                  >
                    {e.nickname ? e.nickname : `Jogador ${i}`}
                  </Typography>
                  {/* <Typography variant="body1">
                    <b>{e.gameValue} pontos</b>
                  </Typography> */}
                </Grid>
              </Grid>
            ))}
            <Button
              endIcon={showRanking ? <ArrowDownward /> : <ArrowUpward />}
              onClick={() => setShowRanking((oldState) => !oldState)}
            >
              Mostrar mais
            </Button>
            {showRanking &&
              info?.game.topPlayers.slice(3).map((e, i) => (
                <Grid
                  key={i}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Typography variant="body1" align="center">
                      {i + 4}° - {e.nickname ? e.nickname : `Jogador ${i}`}
                    </Typography>
                  </Grid>
                  {/*  <Grid item xs={6}>
                    <Typography variant="body1" align="center">
                      <b>{e.gameValue} pontos</b>
                    </Typography>
                  </Grid> */}
                </Grid>
              ))}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default GamesInfo;
