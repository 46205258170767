import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useCallback, useRef, useEffect, useState } from "react";
import axios from "config/axios";

import useStyles from "./styles";
import { GameInfo } from "types";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

import CustomAlert from "components/CustomAlert";
import SadFaceAnimation from "components/SadFaceAnimation";
import CustomLoading from "components/CustomLoading";
import { KeyboardArrowLeft } from "@material-ui/icons";

const GameRules: React.FC = () => {
  const [info, setInfo] = useState<GameInfo>();
  const [loading, setLoading] = useState(false);
  const [showSadFace, setShowSadFace] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ gameId: string }>();

  const alertRef = useRef<any>();

  const getGames = useCallback(async () => {
    setLoading((oldState) => !oldState);
    axios
      .get(`/game/info/${params.gameId}`)
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setInfo(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        setShowSadFace(true);
        switch (error.response.data.status) {
          case 404:
            alertRef?.current.alterAlert("Jogo não encontrado", "warning");
            break;
          case 1:
            alertRef?.current.alterAlert(
              "QR fora do jogo, procure outro QR Code",
              "warning"
            );
            break;
          case 2:
            alertRef?.current.alterAlert(
              "Você já leu este QR Code, tente ler outro",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao pegar dados do jogo",
              "error"
            );
            break;
        }
      });
  }, [params.gameId]);

  useEffect(() => {
    getGames();
  }, [getGames]);

  if (loading) {
    return (
      <div className={classes.center}>
        <CustomAlert ref={alertRef} />
        <CustomLoading />
      </div>
    );
  }

  if (showSadFace) {
    return (
      <div>
        <CustomAlert ref={alertRef} />
        <Paper className={classes.center} style={{ padding: 20 }}>
          <SadFaceAnimation />
          <Typography variant="h5" style={{ marginTop: 10 }}>
            Ops!
          </Typography>
        </Paper>
      </div>
    );
  }

  return (
    <div>
      <CustomAlert ref={alertRef} />
      <Typography variant="h1" className={classes.title}>
        <IconButton
          size={"small"}
          style={{ marginRight: 10 }}
          onClick={() => {
            history.goBack();
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        {info?.game.name}
        {info?.game.icon && (
          <img
            src={info?.game.icon}
            className={classes.gameIcon}
            alt="Ícone do jogo"
          />
        )}
      </Typography>
      <br />
      <Paper className={classes.paper}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item>
            <Typography variant="body1">
              Como funciona: <b>{info?.game.info}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Local do jogo: <b>{info?.game.place.name}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Início da partida:{" "}
              <b>{moment(info?.game?.start).format("DD/MM - HH:mm") || 0}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Término da partida:{" "}
              <b>{moment(info?.game?.end).format("DD/MM - HH:mm") || 0}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Funcionamento:{" "}
              <b>{`${info?.game.place.workingHours.start}h até ${info?.game.place.workingHours.end}h`}</b>
            </Typography>
          </Grid>
          {info?.game && info?.game?.rules?.length > 0 && (
            <Grid item>
              <Typography variant="body1">Regras da partida</Typography>
            </Grid>
          )}
          {info?.game.rules.map((e, i) => {
            return (
              <Grid item key={i}>
                <Typography style={{ marginLeft: 20 }} variant="body1">
                  {i + 1}º - <b>{e}</b>
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </div>
  );
};

export default GameRules;
