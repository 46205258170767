import { createMuiTheme } from "@material-ui/core/styles";

/* const tintColorLight = "#BB0000";
const tintColorDark = "#660000";
export const primary = "#660000";
export const secondary = "#FF4E32";
export const lightBack = "#C8EEFF";
export const iosBlue = "#0563b5"; */

//Cor azul do meio da paleta = #0b87b3

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(101, 175, 181)",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#e679b4",
      contrastText: "#fff",
    },
    action: {
      active: "#DD1C1A",
      hover: "#FF4E3255",
      hoverOpacity: 0.1,
    },
  },
});

export default theme;
