import firebase from "firebase/app";

import axios from "config/axios";

import "firebase/auth";
import "firebase/firebase-messaging";
import "firebase/performance";
import "firebase/firebase-analytics";

const requestNotifyPermission = async () => {
  try {
    await Notification.requestPermission();
  } catch (error) {
    console.log(error);
  }
};

class Fire {
  constructor() {
    let firebaseConfig = {
      apiKey: "AIzaSyAgomWPfqvpSIjmX_NMg29zz0Eon1XwnEI",
      authDomain: "patota-games.firebaseapp.com",
      projectId: "patota-games",
      storageBucket: "patota-games.appspot.com",
      messagingSenderId: "809221509178",
      appId: "1:809221509178:web:98e27b3f88c2fba08da503",
      measurementId: "G-V7YDDRENVP",
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.performance();

    requestNotifyPermission()
      .then(() => {
        if ("serviceWorker" in navigator) {
          //console.log(registration);
          firebase
            .messaging()
            .getToken({
              vapidKey:
                "BMlrsq9dIJb8K9HzoB4uVUt2rgEGHlzr0mJ5n-TBL9Afz6y1vc7BQETUU1XDDgDOOlE5QjhLheFh9JWklGkGaUQ",
            })
            .then(async (currentToken) => {
              if (currentToken) {
                // Send the token to your server and update the UI if necessary
                // ...
                if (process.env.NODE_ENV === "development") {
                  console.log(currentToken);
                } else {
                  await axios.put("/user/update/fcm", {
                    fcmToken: currentToken,
                  });
                }
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                );
                // ...
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              // ...
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async googleLogin() {
    let provider = new firebase.auth.GoogleAuthProvider();
    /* Api para leitura dos números de telefone */
    //provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");
    /* Lendo o gênero da pessoa */
    //provider.addScope("https://www.googleapis.com/auth/user.gender.read");
    /* Lendo os endereços dela */
    //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");
    /* lendo a data de aniversário dela  */
    //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");

    provider.setCustomParameters({
      redirect_uri: "https://app.patota.games",
    });

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // The signed-in user info.
        let user = result.user;
        return { user };
        // ...
      });
  }
  async emailRegister(email: string, password: string) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }
  async sendVerifyEmail() {
    return firebase?.auth()?.currentUser?.sendEmailVerification();
  }
  async emailLogin(email: string, password: string) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((val) => {
        return val;
      });
  }
  async passwordReset(email: string) {
    return firebase.auth().sendPasswordResetEmail(email);
  }
}

export default new Fire();
