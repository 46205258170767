import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: 10,
      borderColor: "grey",
      borderWidth: 2,
      borderStyle: "dashed",
    },
    center: {
      justifyContent: "center",
      textAlign: "center",
    },
    title: {
      fontSize: "1.5rem",
    },
    cardTitle: {
      fontSize: "1.3rem",
    },
    sadFace: {
      width: "40%",
      maxWidth: 100,
    },
    noGame: {
      width: "20%",
      maxWidth: 60,
    },
  })
);
export default useStyles;
