import React, { useState, useRef, useEffect } from "react";

import {
  TextField,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  LinearProgress,
  useMediaQuery,
} from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";

import useStyles from "./styles";
import axios from "config/axios";
import { setUserToken } from "functions/userToken";
import useMobile from "use-mobile-detect-hook";

import { useHistory, useParams } from "react-router-dom";

import CustomAlert from "components/CustomAlert";

import firebase from "config/firebase";

import logo from "assets/img/picbit_logo.svg";
import logo_branco from "assets/img/picbit_logo_branco.svg";
import loginGoogleImage from "assets/img/auth/btn_google.png";
import background from "assets/img/blob_back.svg";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Entre um email válido")
    .required("O email é obrigatório"),
  password: yup.string().required("A senha é obrigatória"),
});

function Login() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const mobile = useMobile();

  const params = useParams<{ qrUuid?: string }>();

  const matches = useMediaQuery("(max-width:1280px)");

  const alertRef = useRef<any>();

  useEffect(() => {
    if (params?.qrUuid) {
      alertRef?.current.alterAlert(
        "Entre em sua conta ou crie uma conta para participar do jogo",
        "info"
      );
    }
  }, [params]);

  const loginGoogle = async () => {
    setLoading(true);
    firebase
      .googleLogin()
      .then(async (val) => {
        axios
          .post("/auth/login", {
            firebaseToken: await val?.user?.getIdToken(true),
          })
          .then(({ data }) => {
            setLoading(false);
            setUserToken(data.token);
            params.qrUuid
              ? history.push(`/dashboard/game/${params.qrUuid}`)
              : history.push("/dashboard/your/games");
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            window.scrollTo(0, 0);
            switch (error?.response?.data?.status) {
              default:
                alertRef?.current.alterAlert(
                  "Problema ao entrar com o google",
                  "error"
                );
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/unauthorized-domain":
            alertRef?.current.alterAlert("Domínio não autorizado", "error");
            break;
          case "auth/cancelled-popup-request":
            return;
          case "auth/popup-closed-by-user":
            return;
          case "auth/user-disabled":
            alertRef?.current.alterAlert(
              "Usuário bloqueado! Entre em contato para saber mais",
              "warning"
            );
            break;
          case "auth/network-request-failed":
            alertRef?.current.alterAlert(
              "Se conecte a internet para fazer login",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao entrar com o google",
              "error"
            );
            break;
        }
      });
  };

  const firebaseLogin = async (values: { email: string; password: string }) => {
    setLoading(true);
    firebase
      .emailLogin(values.email, values.password)
      .then(async (val) => {
        /* if (!val?.user?.emailVerified) {
          setLoading(false);
          return firebase
            .sendVerifyEmail()
            .then(() => {
              return alertRef?.current.alterAlert(
                "Email não verificado, enviamos novamente o email de verificação",
                "warning"
              );
            })
            .catch(() => {
              return alertRef?.current.alterAlert(
                "Email não verificado, olhe sua caixa de entrada",
                "warning"
              );
            });
        } */
        axios
          .post("/auth/login", {
            firebaseToken: await val?.user?.getIdToken(true),
          })
          .then(({ data }) => {
            setLoading(false);
            setUserToken(data.token);
            params.qrUuid
              ? history.push(`/dashboard/game/${params.qrUuid}`)
              : history.push("/dashboard/your/games");
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            window.scrollTo(0, 0);
            switch (error?.response?.data?.status) {
              default:
                alertRef?.current.alterAlert(
                  "Problema ao entrar, tente novamente. CODE: 1",
                  "error"
                );
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/wrong-password":
            alertRef?.current.alterAlert("Senha incorreta", "warning");
            break;
          case "auth/user-not-found":
            alertRef?.current.alterAlert(
              "Usuário não encontrado! CADASTRE-SE no Patota Games",
              "warning"
            );
            break;
          case "auth/user-disabled":
            alertRef?.current.alterAlert(
              "Usuário bloqueado! Entre em contato para saber mais",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao entrar, tente novamente. CODE: 2",
              "error"
            );
            break;
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      firebaseLogin(values);
    },
  });

  return (
    <div>
      {loading && <LinearProgress />}
      <CustomAlert ref={alertRef} />
      <img
        className={classes.background}
        src={background}
        alt="Imagem de fundo"
      />
      <div style={{ flex: 1, textAlign: "center" }}>
        {!mobile.isMobile() && (
          <img
            className={classes.logo}
            src={logo_branco}
            alt="Logo patota games"
            style={matches ? { marginLeft: "4vw" } : undefined}
          />
        )}
      </div>
      <div>
        <Card className={classes.leftSide} style={{ borderRadius: 0 }}>
          <CardContent className={classes.cardContent}>
            {mobile.isMobile() && (
              <div className={classes.logoMobileDiv}>
                <img
                  className={classes.logoMobile}
                  src={logo}
                  alt="Logo patota games"
                />
              </div>
            )}
            <Typography variant="h4" className={classes.title}>
              Bem-vindo
            </Typography>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                className={classes.btnGoogleContainer}
                size="small"
                onClick={loginGoogle}
              >
                <img
                  src={loginGoogleImage}
                  className={classes.btnGoogle}
                  alt="Login com google"
                />
              </Button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Senha"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                style={{ marginTop: "5%" }}
                inputProps={{ style: { padding: 13 } }}
              />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={loading}
                style={{ marginTop: 15 }}
              >
                Entrar
              </Button>
              <div style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    params.qrUuid
                      ? history.push(`/register/${params.qrUuid}`)
                      : history.push("/register");
                  }}
                  style={{ marginTop: 15 }}
                >
                  Crie sua conta
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push("/forgot/password");
                  }}
                  style={{ marginTop: 15 }}
                >
                  Esqueceu a senha?
                </Button>
              </div>
            </form>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </div>
    </div>
  );
}

export default Login;
