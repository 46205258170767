import React, { useState } from "react";
import Lottie, { Options as LottieOptions } from "react-lottie";

import { getRandomArbitrary } from "functions/getRandomNumber";

import consoleAnimation from "assets/animations/console-loading.json";
import ovniAnimation from "assets/animations/ovni.json";
import whaleAnimation from "assets/animations/whale.json";

const CustomLoading: React.FC = () => {
  const [random] = useState(getRandomArbitrary(0, 2));

  switch (random) {
    case 0: {
      const defaultOptions: LottieOptions = {
        loop: true,
        autoplay: true,
        animationData: consoleAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      return <Lottie options={defaultOptions} height={200} width={150} />;
    }
    case 1: {
      const defaultOptions: LottieOptions = {
        loop: true,
        autoplay: true,
        animationData: ovniAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      return <Lottie options={defaultOptions} height={200} width={150} />;
    }
    case 2: {
      const defaultOptions: LottieOptions = {
        loop: true,
        autoplay: true,
        animationData: whaleAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      return <Lottie options={defaultOptions} height={200} width={150} />;
    }
    /*  case 3: {
      const defaultOptions: LottieOptions = {
        loop: true,
        autoplay: true,
        animationData: consoleAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      return <Lottie options={defaultOptions} height={200} width={150} />;
    } */
    default:
      return <></>;
  }
};

export default CustomLoading;
